<script lang="ts" setup>
import type { HomeBanners } from "@/types";

const props = defineProps<{ banner: HomeBanners[number]; fetchpriority?: string }>();

const { open } = useFunrizeModals();
const localPath = useLocalePath();
const isGuest = useIsGuest();
const { handleOpenGame } = useOpenGame(open);
const { registrationRewards, registrationRewardWithoutCode } = useCheckRewardsData({ immediate: isGuest.value });
const { data: registerContent } = useRegisterContent();

const coins = computed(() => registrationRewards.value?.coins || registrationRewardWithoutCode.value?.coins);
const entries = computed(() => registrationRewards.value?.entries || registrationRewardWithoutCode.value?.entries);
// const title = computed(() =>
// 	(registerContent.value?.title || props.banner?.title || "")
// 		.replace(/{coins}/g, numberFormat(coins.value ?? 0))
// 		.replace(/{entries}/g, numberFormat(entries.value ?? 0))
// );
// const smallTitle = computed(() => registerContent.value?.subtitle_1 || props.banner?.smallTitle || "");

const title = computed(() => {
	const guestTitle = isGuest.value ? registerContent.value?.title : "";
	return (guestTitle || props.banner?.title || "")
		.replace(/{coins}/g, numberFormat(coins.value ?? 0))
		.replace(/{entries}/g, numberFormat(entries.value ?? 0));
});

const smallTitle = computed(() => {
	const guestTitle = isGuest.value ? registerContent.value?.subtitle_1 : "";
	return guestTitle || props.banner?.smallTitle || "";
});

const smallSubTitle = computed(() => (isGuest.value ? registerContent.value?.subtitle_2 : null));

const handleBannerClick = () => {
	if (isGuest.value || props.banner.buttonLink === "/register/") {
		open("LazyOModalSignup");
		return;
	}
	if (!props.banner.buttonLink) {
		return;
	}
	if (props.banner.buttonLink.includes("facebook")) {
		window.open(props.banner.buttonLink, "_blank");
		return;
	}
	if (props.banner.buttonLink.includes("game=")) {
		const slug = props.banner.buttonLink.replace("game=", "");
		dispatchGAEvent({
			event: "click_button",
			button_name: "money_play",
			location: slug,
			form_name: "banner"
		});
		handleOpenGame(slug);
		return;
	}

	navigateTo(localPath(props.banner.buttonLink));
};
</script>
<template>
	<MBanner
		:image="banner.image2x"
		:image-mobile="banner.imageMobile2x"
		:bg-color="banner.background"
		:fetchpriority="fetchpriority"
	>
		<AText variant="tucson" :modifiers="['bold', 'uppercase']" as="h2" class="title">
			<span v-html="title" />
		</AText>
		<AText variant="tanta" :modifiers="['center']" as="p" class="subtitle">
			<span v-html="smallTitle" />
		</AText>
		<AText v-if="smallSubTitle" variant="tanta" :modifiers="['center']" as="p" class="subtitle">
			<span v-html="smallSubTitle" />
		</AText>
		<AButton variant="primary" size="lg" @click="handleBannerClick">
			<AText :modifiers="['uppercase']" variant="toledo"> {{ banner.buttonText }} </AText>
		</AButton>
	</MBanner>
</template>
